.calcBtns {
    height: 90px;
    width: 90px;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #f4f1f1;
    user-select: none;
    box-shadow: 10px 10px 30px #bebebe,
    -10px -10px 30px #fffff;
}

.res {
    width: 200px;
    background-color: rgb(236, 166, 37);
}