*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.home {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner {
    background-color: red;
    height: 640px;
    width: 425px;
    border-radius: 30px;
    background-color: #abf6b4;
    box-shadow: 20px 20px 60px #bebebe,
    -20px -20px 60px #ffffff;
}

.result {
    font-size: 2rem;
    width: 405px;
    height: 90px;
    scrollbar-width: none;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 30px;
    background: white;
    box-shadow: 10px 10px 30px #bebebe,
    -10px -10px 30px #ffffff;
}

.resbox {
    width: 390px;
    height: min-content;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
    padding-top: 18px;
}

.resbox::-webkit-scrollbar {
    display: none;
}

.btns {
    height: 530px;
    width: 435px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 30px;
}